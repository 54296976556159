import React from 'react';
import Layout from '../components/Layout';

const EasyLanguage = () => {
  return (
    <Layout pageTitle="Datenschutzerklärung" pageUrl="datenschutzhinweis">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Datenschutzerklärung
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <h2>1. Name und Anschrift des Verantwortlichen</h2>
          <p>Der Verantwortliche im Sinne der DSGVO und des Bundesdatenschutzgesetzes – BDSG-neu – ist der</p>
          <p>
            <strong>Chef des Presse- und Informationsamtes der Bundesregierung</strong><br />
            Dorotheenstraße 84<br />
            10117 Berlin<br />
            Telefon: 030 18272-0<br />
            Fax: 030 18272-2555<br />
            <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a>
          </p>
          <p>
            <strong>Realisierung</strong><br />
            3pc GmbH<br />
            Prinzessinnenstraße 1<br />
            10969 Berlin<br />
            Internet: <a href="https://3pc.de/" target="_blank" rel="nofollow noopener noreferrer">3pc.de<span className="sr-only">(öffnet ein neues Fenster)</span></a>
          </p>

          <h2>2. Name und Anschrift des Datenschutzbeauftragten</h2>
          <p>
            Wolfgang Schlesener<br />
            Presse- und Informationsamt der Bundesregierung – Datenschutzbeauftragter –<br />
            Dorotheenstraße 84<br />
            10117 Berlin<br />
            Telefon: 030 18272-0<br />
            Fax: 030 18272-2555<br />
            <a href="mailto:datenschutzbeauftragte@bpa.bund.de">datenschutzbeauftragte@bpa.bund.de</a>
          </p>

          <h2>3. Beschreibung und Umfang der Datenverarbeitung</h2>
          <p>
            Bei jedem Aufruf unserer Internetseite erfasst unser System
            automatisiert Daten und Informationen vom Computersystem des aufrufenden
            Rechners, geloggt werden folgende Daten:
          </p>
          <ul>
            <li><strong>Informationen über den Browsertyp und die verwendete Version</strong></li>
            <li><strong>das Betriebssystem des Nutzers</strong></li>
            <li><strong>die IP-Adresse des Nutzers auf den Auslieferungsservern für 60 Tage</strong></li>
            <li><strong>Datum und Uhrzeit des Zugriffs</strong></li>
            <li><strong>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt, werden als Referrer gespeichert</strong></li>
          </ul>

          <h2>4. Rechtsgrundlage für die Datenverarbeitung</h2>
          <p>
            Rechtsgrundlage für die vorübergehende Speicherung der Daten und der
            Logfiles ist Art. 6 Abs. 1 lit. e DSGVO i. V. m. § 3 BDSG-neu.
          </p>

          <h2>5. Zweck der Datenverarbeitung</h2>
          <p>
            Die vorübergehende Speicherung der IP-Adresse durch das System ist
            notwendig, um eine Auslieferung der Webseite an den Rechner des Nutzers
            zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
            der Sitzung gespeichert bleiben.
          </p>
          <p>
            Die IP wird auf den Auslieferungsservern für 60 Tage gespeichert. Dies
            dient der Sicherstellung des Betriebs und dem Schutz vor Angriffen (z. B. DDOS).
          </p>

          <h2>6. Dauer der Speicherung</h2>
          <p>Die Logfiles werden zentral gespeichert und nach 60 Tagen gelöscht.</p>

          <h2>7. Widerspruchs- und Beseitigungsmöglichkeit</h2>

          <p>
            Die Erfassung der Daten zur Bereitstellung der Website und die
            Speicherung der Daten in Logfiles sind für den Betrieb der Internetseite
            zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
            Widerspruchsmöglichkeit.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default EasyLanguage;
